nav{
  width:100vw;
  background-color: var(--accentColor);
}

nav ul{
  display:flex;
  flex-direction: row;
  justify-content:flex-end;
  list-style-type: none;
  padding-right: 20px;
  font-variant: small-caps;
}

.socialLinks{
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  list-style-type: none;
  padding-left: 20px;
}

.socialLinks a{
  border-radius: 0px;
  padding: 5px;
}

nav li a:hover{
    background-color: var(--accentColorDark);
    color: var(--darkText);
}

nav a{
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding:5px 10px;
  color: var(--lightText);
  border-radius: 5px 5px 0px 0px;
  margin:0px 2px;
}

.selected:hover{
  background-color: var(--accentColorLight);
  color:var(--lightText);
}

.selected{
  background-color: var(--accentColorLight);
  color:var(--lightText);
  cursor: default;
}

#logo{
  color:var(--lightText);
  font-size: 2.5em;
}
