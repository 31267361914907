.gameEntry img{
  width: 200px;
}

.gameEntry a{
  display: block;
  font-weight: bold;
}

.gameEntry iframe{
  display: block;
}

.gameEntry figure{
  display:inline-block;
}
.gameEntry figure{
  text-align: center;
  font-size: .8em;
}

ul{
  display:flex;
  font-weight: bold;
  /* width: 50vw; */
  flex-direction: row;
  justify-content:space-evenly;
  flex-wrap:wrap;
  list-style-type: none;
  line-height: 2em;
  /* padding-right: 50px; */
  font-variant: small-caps;
}

ul a{
  padding: 10px;
}
