/* //060342; */
/* CSS variables for the whole site */
:root{
  --accentColor:#6495ED;
  --accentColorLight:#96BAFA;
  --accentColorDark:#4581ED;
  --darkText:#000;
  --lightText:#FFF;
}

body{
  background-color:#fff;
  color: var(--darkText);
  font-family: sans-serif;
  margin:0px;
}

a{
  text-decoration: none;
  color: #000;
}

.twoColumn{
  display: flex;
  flex:2;
  flex-flow:row wrap;
  justify-content: flex-start;
}

.content{
  margin:0px 20px;
}

.project{
  /* flex:1;  */
  /* flex: 50%; */
  width:200px;
  background-color: var(--accentColorLight);
  border-radius: 5px;
  /*justify-content: center; */
  padding:10px;
  margin:10px;
}

.project img{
  width:200px;
}
